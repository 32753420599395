<template>
  <v-responsive>
    <v-container fluid class="px-0 py-0">
      <div class="social">
        <ul class="pl-0">
          <li>
            <a
              class="icon-instagram"
              style="display: inline-block"
              @click="
                HandlerWindowNew(
                  'https://www.instagram.com/pilsala/',
                  'instagram'
                )
              "
            >
              <v-avatar tile size="50">
                <v-img src="../assets/img/RRSS-1.webp" contain></v-img>
              </v-avatar>
            </a>
          </li>
          <li>
            <a
              style="display: inline-block"
              class="icon-facebook"
              @click="
                HandlerWindowNew(
                  'https://www.facebook.com/PILSA-107456954766217',
                  'facebook'
                )
              "
            >
              <v-avatar tile size="50">
                <v-img src="../assets/img/RRSS-2.webp" contain></v-img>
              </v-avatar>
            </a>
          </li>
          <li>
            <!-- href="https://www.youtube.com/channel/UCOFfCqO3gg1NC5-3rqpUiow" -->
            <a
              style="display: inline-block"
              class="icon-youtube"
              @click="
                HandlerWindowNew(
                  'https://www.youtube.com/channel/UCOFfCqO3gg1NC5-3rqpUiow',
                  'youtube'
                )
              "
            >
              <v-avatar tile size="50">
                <v-img src="../assets/img/RRSS-3.webp" contain></v-img>
              </v-avatar>
            </a>
          </li>
          <li>
            <a style="display: inline-block" class="icon-compartir">
              <button type="button" @click="HandlerCopyData">
                <v-avatar tile size="50">
                  <v-img src="../assets/img/RRSS-4.webp" contain></v-img>
                </v-avatar>
              </button>
            </a>
          </li>
        </ul>
      </div>
      <v-row>
        <!-- <v-img
        src="../assets/img/Background Landing.webp"
        aspect-ratio="16/9"
        height="auto"
        width="100%"
        class="pt-10"
      > -->
        <div
          style="
            background-image: url('../../img/Background Landing.webp');
            height: auto;
            background-repeat: no-repeat !important;
            background-size: cover !important;
          "
        >
          <v-col cols="12" class="d-flex flex-column">
            <v-app-bar color="transparent elevation-0" ligth tile height="120">
              <v-avatar size="230" tile class="mt-8">
                <v-img
                  src="../assets/img/Logo-Pilsa-Superior.webp"
                  alt="Logo Pilsa"
                  contain
                />
              </v-avatar>
            </v-app-bar>
            <v-row>
              <v-col
                style="padding-bottom: 7em"
                cols="12"
                sm="12"
                md="6"
                class="align-self-end px-15 white--text order-md-0 order-1"
              >
                <div class="text-italic text-center ml-md-15 pl-md-15">
                  Vivimos una nueva realidad, sabemos que necesitas herramientas
                  de aprendizaje que te permitan avanzar en tu trabajo, tu vida
                  y en la sociedad.
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="px-15 order-md-1 order-0">
                <div class="text-center white--text mx-md-10">
                  <span class="text-title-bold">¡Pilsa</span>
                  <span class="text-title-light"> es tu </span>
                  <span class="text-title-bold">mejor Opción</span>
                  <span class="text-title-light"> de </span>
                  <span class="text-title-bold">Formación!</span>
                </div>
                <v-card
                  tile
                  class="py-8 px-8 mt-15 white--text"
                  flat
                  color="#061F42"
                  style="border-radius: 20px !important"
                >
                  <div class="text-center text-text-bold">
                    Ingresa tus datos y experimenta la mejor manera de aprender
                    con tan solo invertir 15 minutos
                  </div>
                  <ValidationObserver ref="obs" v-slot="{ passes }">
                    <div class="mt-12">
                      <div>
                        <ValidationProvider
                          name="nombre"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label for="nombre">Nombre *</label>
                          <v-text-field
                            id="nombre"
                            solo
                            dense
                            class="mt-2"
                            v-model="name"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div>
                        <ValidationProvider
                          name="apellido"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label for="apellido">Apellido *</label>
                          <v-text-field
                            id="apellido"
                            solo
                            dense
                            class="mt-2"
                            v-model="last_name"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <ValidationProvider
                              name="código de área"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <label for="codigo">Código de área *</label>
                              <v-autocomplete
                                id="codigo"
                                :items="countries"
                                :item-text="
                                  (item) => item.name + ' ' + item.phone_code
                                "
                                :item-value="(item) => item"
                                v-model="country_id"
                                class="mt-2"
                                solo
                                dense
                                :error-messages="errors"
                              >
                                <template v-slot:[`selection`]="{ item }">
                                  <v-avatar class="px-0 mr-0" size="20">
                                    <v-img :src="item.flag"></v-img>
                                  </v-avatar>
                                  <span>{{ item.phone_code }}</span>
                                </template>
                                <template v-slot:[`item`]="{ item }">
                                  <v-avatar class="mr-2" size="20">
                                    <v-img :src="item.flag"></v-img>
                                  </v-avatar>
                                  <span>{{ item.name }}</span>
                                </template>
                              </v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="8">
                            <ValidationProvider
                              name="celular"
                              rules="required|numeric"
                              v-slot="{ errors }"
                            >
                              <label for="celular">Celular *</label>
                              <v-text-field
                                id="celular"
                                solo
                                dense
                                class="mt-2"
                                v-model="phone_number"
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </div>
                      <div>
                        <ValidationProvider
                          name="email"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <label for="email">Email *</label>
                          <v-text-field
                            id="email"
                            solo
                            dense
                            class="mt-2"
                            v-model="email"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div>
                        <ValidationProvider
                          name="nombre empresa"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label for="nombre_empresa"
                            >Nombre de la empresa *</label
                          >
                          <v-text-field
                            id="nombre_empresa"
                            solo
                            dense
                            class="mt-2"
                            v-model="name_company"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div class="text-center mt-10">
                        <v-btn
                          :loading="loading"
                          color="#EA5899"
                          dark
                          @click="passes(HandlerSaveForm)"
                        >
                          Enviar
                        </v-btn>
                      </div>
                    </div>
                  </ValidationObserver>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="my-15">
              <v-col cols="12" sm="12" md="12" class="mt-15">
                <div class="text-center white--text">
                  <span class="text-title-bold">4</span>
                  <span class="text-title-light">
                    beneficios de suscribirse a
                  </span>
                  <span class="text-title-bold">Pilsa</span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-container>
                <v-row class="white--text mx-4">
                  <v-col cols="12" sm="6" md="3" class="text-center">
                    <v-img
                      src="../assets/img/Beneficio_1.webp"
                      lazy-src="../assets/img/Beneficio_1.webp"
                      class="border-white"
                    ></v-img>
                    <div class="mt-4 text-text-bold">Movilidad</div>
                    <div class="mt-4">
                      Tienes acceso a todos tus contenidos 24/7 desde tu
                      celular.
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="text-center">
                    <v-img
                      src="../assets/img/Beneficio_2.webp"
                      lazy-src="../assets/img/Beneficio_2.webp"
                      class="border-white"
                    ></v-img>
                    <div class="mt-4 text-text-bold">Efectivo</div>
                    <div class="mt-4">
                      Nuestra propuesta te ofrece contenidos esenciales y
                      específicos para avanzar.
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="text-center">
                    <v-img
                      src="../assets/img/Beneficio_3.webp"
                      lazy-src="../assets/img/Beneficio_3.webp"
                      class="border-white"
                    ></v-img>
                    <div class="mt-4 text-text-bold">Productivo</div>
                    <div class="mt-4">
                      Invierte pocos minutos al día y adquiere conocimientos que
                      te permitan progresar.
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="text-center">
                    <v-img
                      src="../assets/img/Beneficio_4.webp"
                      lazy-src="../assets/img/Beneficio_4.webp"
                      class="border-white"
                    ></v-img>
                    <div class="mt-4 text-text-bold">Variedad</div>
                    <div class="mt-4">
                      Podrás tener acceso a audios, videos, animaciones,
                      documentos y muchos recursos que necesitas para tu
                      crecimiento personal y profesional.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-15">
                <v-footer class="d-flex align-end mt-15" color="transparent">
                  <v-row>
                    <v-col cols="12" class="d-flex justify-center mt-15">
                      <v-avatar
                        size="200"
                        tile
                        @click="HandlerRedirectPrincipal()"
                      >
                        <v-img
                          src="../assets/img/Logo_Pilsa_Blanco_Inferior.webp"
                          class="mx-auto"
                          aspect-ratio="2"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn
                        aria-label="instagram"
                        class="mx-2"
                        fab
                        dark
                        color="transparent"
                        small
                        @click="handlerSocialNetwork('instagram')"
                      >
                        <v-icon dark large> mdi-instagram </v-icon>
                      </v-btn>
                      <v-btn
                        aria-label="facebook"
                        class="mx-2"
                        fab
                        dark
                        color="transparent"
                        small
                        @click="handlerSocialNetwork('facebook')"
                      >
                        <v-icon dark large> mdi-facebook </v-icon>
                      </v-btn>
                      <v-btn
                        aria-label="youtube"
                        class="mx-2"
                        fab
                        dark
                        color="transparent"
                        small
                        @click="handlerSocialNetwork('youtube')"
                      >
                        <v-icon dark large> mdi-youtube </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <div class="white--text text-center">
                        <a
                          href="#"
                          @click.prevent="HandlerShowConditions"
                          style="color: white"
                        >
                          Política de privacidad. Términos y condiciones
                        </a>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="white--text text-center mb-10">
                        2021 Pilsala - Todos los derechos reservados.
                        <!-- "Hecho con ❤️ por VILCA". -->
                      </div>
                      <input
                        type="text"
                        v-model="message"
                        style="visibility: hidden"
                      />
                      <!-- <button type="button" @click="doCopy">Copy!</button> -->
                    </v-col>
                  </v-row>
                </v-footer>
              </v-col>
            </v-row>

            <!-- </v-img> -->
          </v-col>
        </div>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
import { api } from "@/services";
import VueClipboard from "vue-clipboard2";
import Vue from "vue";
Vue.use(VueClipboard);

export default {
  name: "HelloWorld",

  data: () => ({
    loading: false,
    countries: [],
    name: "",
    last_name: "",
    phone_number: "",
    email: "",
    name_company: "",
    country_id: {},
    country_usa: {},
    message: "https://pilsa.io/",
  }),
  created() {
    this.HandlerGetCountries();
  },
  methods: {
    async HandlerGetCountries() {
      try {
        const response = await api.get("/api/v1/country");
        this.country_id = response.data.data.find((val) => val.id == 238);
        this.countries = response.data.data;
        this.country_usa = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async HandlerSaveForm() {
      try {
        this.loading = true;
        const request = {
          name: this.name,
          last_name: this.last_name,
          phone_number: this.phone_number,
          email: this.email,
          name_company: this.name_company,
          country_id: this.country_id.id,
        };
        console.log("request", request);
        await api.post("/api/v1/interested", request);
        this.$snotify.success(
          "Tu mensaje ha sido enviado con éxito",
          "¡Genial!"
        );
        this.clean();
        this.$refs.obs.reset();
      } catch (error) {
        let e = error.response.data.error;
        if (error.response.status == 422) {
          let list = "";
          for (const prop in e) {
            list = list + `<li>${e[prop][0]}</li>`;
          }
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   html: list,
          // });
        } else {
          this.$snotify.error(
            "Ha ocurrido un error, porfavor intenta mas tarde",
            "Error!"
          );
        }
      } finally {
        this.loading = false;
      }
    },
    async HandlerCopyData() {
      await this.$copyText(this.message);
      this.$snotify.success(this.message, "Copiado");
      // this.$copyText(this.message).then(
      // function (e) {
      //   this.$snotify.error(
      //     "Ha ocurrido un error, porfavor intenta mas tarde",
      //     "Error!"
      //   );
      //   alert("Copied");
      //   console.log(e);
      // },
      // function (e) {
      //   this.$snotify.error(
      //     "Ha ocurrido un error, porfavor intenta mas tarde",
      //     "Error!"
      //   );
      //   alert("Can not copy");
      //   console.log(e);
      // }
      // );
    },
    HandlerWindowNew(url, name) {
      window.open(url, name, "width=500,height=500,scrollbars=NO");
    },
    HandlerShowConditions() {
      window.location = "https://pilsa.io/conditions";
    },
    HandlerRedirectPrincipal() {
      window.open("https://pilsa.io", "_blank");
    },
    clean() {
      this.name = "";
      this.last_name = "";
      this.phone_number = "";
      this.email = "";
      this.name_company = "";
    },
    handlerSocialNetwork(social) {
      if (social == "youtube") {
        window.open(
          "https://www.youtube.com/channel/UCOFfCqO3gg1NC5-3rqpUiow",
          "_blank"
        );
      } else if (social == "facebook") {
        window.open("https://www.facebook.com/PILSA-107456954766217", "_blank");
      } else if (social == "whatsapp") {
        window.open(
          "https://wa.me/+14243914101?text=Hola%20Bienvenidoa%20Pilsa",
          "_blank"
        );
      } else if (social == "instagram") {
        window.open("https://www.instagram.com/pilsala/", "_blank");
      }
    },
  },
};
</script>
<style>
.border-white {
  border-radius: 20px !important;
  border: 2px solid white !important;
}
.social {
  position: fixed; /* Hacemos que la posición en pantalla sea fija para que siempre se muestre en pantalla*/
  left: 0; /* Establecemos la barra en la izquierda */
  top: 300px; /* Bajamos la barra 200px de arriba a abajo */
  z-index: 2000; /* Utilizamos la propiedad z-index para que no se superponga algún otro elemento como sliders, galerías, etc */
}

.social ul {
  list-style: none;
}

.social ul li a {
  display: inline-block;
  color: #fff;
  background: transparent;
  padding: 0px 0px;
  text-decoration: none;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; /* Establecemos una transición a todas las propiedades */
}

.social ul li .icon-facebook {
  background: #314a7f;
} /* Establecemos los colores de cada red social, aprovechando su class */
.social ul li .icon-instagram {
  background: #5a2095;
}
.social ul li .icon-googleplus {
  background: #d95232;
}
.social ul li .icon-youtube {
  background: #d4071c;
}
.social ul li .icon-mail {
  background: #666666;
}
.social ul li .icon-compartir {
  background: #0bcad1;
}
.social ul li .icon-instagram:hover {
  background: #5a2095; /* Cambiamos el fondo cuando el usuario pase el mouse */
  padding: 0px 30px; /* Hacemos mas grande el espacio cuando el usuario pase el mouse */
}

.social ul li .icon-facebook:hover {
  background: #314a7f; /* Cambiamos el fondo cuando el usuario pase el mouse */
  padding: 0px 30px; /* Hacemos mas grande el espacio cuando el usuario pase el mouse */
}

.social ul li .icon-youtube:hover {
  background: #d4071c; /* Cambiamos el fondo cuando el usuario pase el mouse */
  padding: 0px 30px; /* Hacemos mas grande el espacio cuando el usuario pase el mouse */
}

.social ul li .icon-compartir:hover {
  background: #0bcad1; /* Cambiamos el fondo cuando el usuario pase el mouse */
  padding: 0px 30px; /* Hacemos mas grande el espacio cuando el usuario pase el mouse */
}
</style>
