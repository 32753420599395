<template>
  <v-app>
    <v-main>
      <!-- <v-img
        src="./assets/img/Background Landing.webp"
        aspect-ratio="16/9"
        height="auto"
        width="100%"
        class="pt-10"
      > -->
        
        <router-view />

        <!-- notificaciones -->
        <vue-snotify></vue-snotify>
      <!-- </v-img> -->
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-display: block;
  /* color: #4d4d4e; */
}

.text-title-bold {
  font-weight: 600;
  font-size: 3em;
}

.text-title-light {
  font-weight: 400;
  font-size: 3em;
}

.text-text-bold {
  font-weight: 600;
  font-size: 1.2em;
}

.text-italic {
  font-weight: 600;
  font-size: 1.5em;
  font-style: italic;
}
</style>
